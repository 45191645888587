body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F2F2;
  min-height: 100vh;
}
:root {
  --Dwidth: 75%;
  --Mwidth: 154%;
  --contentHeight: 300px;
  --contentColor: #e5e5e5;
  /* --contentColor: transparent; */

  --green: #4caf50;
  --red: #ff5252;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
  /* height: 50px; */
}
::-webkit-scrollbar-thumb {
  background-color: var(--green);
  border-radius: 20px;
  padding-top: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3a8c3d;
}

/* ----------------------Navbar---------------------------- */
.navbar-wrap {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  position: fixed;
  background-color: #f2f2f2;
}
.navbar-wrap.activeNav {
  height: 60px;
  /* background-color: #f2f2f2; */
  z-index: 2;
}
.navbar {
  width: var(--Dwidth);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-wrap h3 {
  font-size: 21px;
  font-weight: 500;
  cursor: pointer;
}
.logo-wrap {
  height: auto;
  position: relative;
}
sup {
  font-size: 9px;
  position: absolute;
  font-weight: 500;
  color: #464646;
}
.catagory-wrap {
  width: 324px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
}
.catagory-wrap p {
  cursor: pointer;
}
.catagory-wrap p:hover {
  color: var(--green);
}
.hire-wrap {
  height: 36px;
  width: 150px;
  border-radius: 151px;
  box-shadow: 0 0 0 1px var(--green);
  color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.hire-wrap:hover {
  background-color: var(--green);
  color: white;
}
.hire-wrap p {
  font-size: 15px;
  font-weight: 500;
}

/* -----------------Hero--------------- */
.hero-wrap {
  margin-top: 90px;
  width: 100%;
  height: calc(100vh - 90px);
  background-color: var(--contentColor);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
.hero {
  width: var(--Dwidth);
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.hero-content-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-content {
  /* width: 423px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #2f2f2f;
}
.hero-content-wrap h1 {
  font-weight: 300;
  /* font-size: 36px; */
  margin: -6px -3px;
  padding: 0px;
  text-align: initial;
  line-height: 33px;
}
.hero-content-wrap h3 {
  font-weight: bold;
  font-size: 15px;
  color: var(--green);
  margin: 6px 0px;
  padding: 0px;
  display: flex;
  text-align: initial;
}
.hero-span {
  height: 36px;
  width: 240px;
  box-shadow: 0px 0px 0px 1px var(--red);
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  color: var(--red);
}
.hero-span:hover {
  background-color: var(--red);
  color: white;
}
.hero-image-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
/* .hero-image-wrap{
  background-color: var(--green);
  width: 40%;
} */
.hero-image-wrap img {
  width: auto;
  border-radius: var(--contentHeight) var(--contentHeight) 0px 0px;
  height: 569px;
  z-index: 1;
  filter: drop-shadow(5px -4px 15px rgba(0, 0, 0, 0.21));
}

/* ------------------Service--------------- */
.service-wrap {
  width: 100%;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.service-title-wrap {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.service-title {
  width: var(--Dwidth);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.service-react,
.service-ui {
  width: 130px;
  height: 36px;
  background-color: var(--contentColor);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}
.service-react {
  background-color: var(--green);
  color: white;
}
.service-viewall {
  width: 100px;
  height: 36px;
  background-color: var(--contentColor);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}
.service-title h1 {
  color: rgba(120, 120, 120, 0.1);
  font-weight: bold;
  font-size: 60px;
  cursor: default;
}
.service-btn-wrap {
  width: 270px;
  display: flex;
  justify-content: space-between;
}
.service {
  width: 100%;
  min-height: var(--contentHeight);
  background-color: var(--contentColor);
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-content-wrap {
  height: 100%;
  width: var(--Dwidth);
}
.service-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.service-images {
  height: calc(152px + 72px);
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-1 {
  width: 160px;
  height: 151px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-1 img {
  width: 160px;
  height: auto;
}
.image-2 {
  width: 100px;
  height: 72px;
  position: absolute;
  top: -35px;
  left: -50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.image-2 img {
  width: 100px;
}
.image-3 {
  width: 100px;
  height: 72px;
  position: absolute;
  right: -50px;
  bottom: -35px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.image-3 img {
  width: 100px;
}
.service-image-content {
  width: 470px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.service-image-content h3,
p {
  margin: 0px;
  padding: 0px;
}
.service-image-content h3 {
  color: var(--red);
}
.service-image-content p {
  color: #2f2f2f;
  text-align: end;
  font-weight: lighter;
}
.service-image-content li{
  list-style: none;
}
.service-image-content ul{
  margin-top: 3px;
}
/* ----------------------About----------------- */
.about-wrap {
  width: 100%;
  height: 450px;
}
.about-title {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.about-title h1 {
  color: rgba(120, 120, 120, 0.1);
  font-weight: bold;
  font-size: 60px;
  cursor: default;
  margin: 0px;
  padding: 0px;
}
.about-content-wrap {
  width: 100%;
  height: var(--contentHeight);
  background-color: var(--contentColor);
  display: flex;
  justify-content: center;
}
.about-content {
  width: var(--Dwidth);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-content-letter {
  width: 470px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-content-letter p {
  text-align: start;
  margin: 0px;
  padding: 0px;
  color: #2f2f2f;
  font-weight: lighter;
}
.about-content-letter h3 {
  margin: 0px;
  padding: 0px;
  color: var(--red);
}
.about-content-scroll {
  width: 97%;
  height: 55px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 0px 0.5px #5f5f5f; */
  margin-top: 24px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
  position: relative;
  padding: 7px;
}
.about-content-scroll-wrap {
  width: 100%;
  position: relative;
  /* height: 55px; */
}
.scroll-overlap {
  width: 100%;
  height: 55px;
  background-color: violet;
  position: absolute;
  top: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0.5px #5f5f5f;
  background-color: transparent;
}
.about-content-scroll::-webkit-scrollbar {
  height: 5px;
}
.about-content-scroll::-webkit-scrollbar-track {
  background: transparent;
  height: 5px;
}
.about-content-scroll::-webkit-scrollbar-thumb {
  background-color: #8c8c8c;
  border-radius: 5px;
  /* box-shadow: 0px 0px 0px 1px #5f5f5f; */
}
.about-content-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #414141;
}
.about-content-scroll::-webkit-scrollbar-button {
  width: 10px;
}
.scroll-1 {
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  border-radius: 50%;
  margin-left: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}
.scroll-1 img {
  width: 40px;
  height: auto;
}

/* ---------------Content---------------- */
.content-wrap {
  width: 100%;
  height: 450px;
}
.content-title-wrap {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.content-title {
  width: var(--Dwidth);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-data-wrap {
  width: 100%;
  height: var(--contentHeight);
  background-color: var(--contentColor);
  display: flex;
  justify-content: center;
}
.content-title-types {
  width: 341px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-viewall {
  width: 100px;
  height: 36px;
  border-radius: 16px;
  background-color: var(--contentColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.content-title h1 {
  color: rgba(120, 120, 120, 0.1);
  font-weight: bold;
  font-size: 60px;
  cursor: default;
  margin: 0px;
  padding: 0px;
}
.title-type-professional,
.title-type-development {
  width: 105px;
  height: 36px;
  border-radius: 16px;
  background-color: var(--contentColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.title-type-design {
  width: 105px;
  height: 36px;
  border-radius: 16px;
  background-color: var(--green);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.content-data {
  width: var(--Dwidth);
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}
.content-data::-webkit-scrollbar {
  height: 10px;
}
.content-data::-webkit-scrollbar-track {
  background: transparent;
}
.content-data::-webkit-scrollbar-thumb {
  background-color: var(--green);
  border-radius: 20px;
}
.content-data::-webkit-scrollbar-thumb:hover {
  background-color: #3a8c3d;
}
.content-data-design,
.content-data-development,
.content-data-professional {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.design-data-card,
.development-data-card,
.professional-data-card {
  width: 200px;
  height: 250px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  flex-shrink: 0;
}
.card-image {
  width: 100%;
  height: 100px;
}
.card-image img {
  width: 100%;
  height: auto;
}
.card-letter-content {
  width: calc(100% - 20px);
  height: 100px;
  padding: 10px;
  text-align: left;
}
.card-letter-content h1 {
  font-size: 18px;
  font-weight: 500;
  color: #484848;
  margin-top: 6px;
  padding: 0px;
}
.card-letter-content p {
  font-size: 12px;
  font-weight: 300;
  color: #3e3e3e;
  margin-top: 6px;
}
.card-button {
  width: 100%;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--green);
  box-shadow: 0 0 0 1px var(--green);
  font-weight: 500;
  cursor: pointer;
}
.card-button:hover {
  background-color: var(--green);
  color: white;
}

/* --------------Contact--------------- */
.contact-wrap {
  width: 100%;
  height: 450px;
}
.contact-title-wrap {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.contact-title {
  width: var(--Dwidth);
  height: 80px;
}
.contact-title h1 {
  color: rgba(120, 120, 120, 0.1);
  font-weight: bold;
  font-size: 60px;
  cursor: default;
  margin: 0px;
  padding: 0px;
}
.contact-content-wrap {
  width: 100%;
  height: var(--contentHeight);
  background-color: var(--contentColor);
  display: flex;
  justify-content: center;
}
.contact-content {
  width: var(--Dwidth);
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.contact-content-image {
  width: 48%;
  height: 100%;
  /* border-radius: 0px 50px 0px 50px; */
  box-shadow: 0px 0px 3px var(--green);
  background-color: black;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.contact-content-image img {
  /* height: var(--contentHeight); */
  width: 120%;
  opacity: 0.5;
  position: absolute;
}
.contact-content-field {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}
.contact-content-field form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.contact-content-field input {
  width: 100%;
  height: 30px;
  border: none;
  margin: 0;
  box-shadow: 0px 0px 0px 0.5px #5f5f5f;
  background-color: transparent;
  text-align: center;
  /* -webkit-appearance: none; */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* margin: 0; */
}
.contact-content-field input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 1.5px var(--green);
}
.contact-content-field textarea {
  max-width: 100%;
  min-width: 100%;
  max-height: 88px;
  min-height: 88px;
  margin: 0;
  border: none;
  box-shadow: 0px 0px 0px 0.5px #5f5f5f;
  background-color: transparent;
}
.contact-content-field textarea:focus {
  outline: none;
  box-shadow: 0px 0px 0px 1.5px var(--green);
}
.contact-content-field button {
  width: 101.5%;
  height: 36px;
  background-color: var(--green);
  border: var(--green);
  font-weight: 500;
  font-size: 15px;
  color: white;
  cursor: pointer;
}
.contact-content-image-social {
  width: 200px;
  height: 100%;
  display: flex;
  /* display: none; */
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  z-index: 2;
}
.contact-content-image-social i {
  color: white;
}
.contact-content-image-social i:hover {
  color: var(--green);
}
.contact-content-social {
  width: 200px;
  height: 100%;
  /* display: flex; */
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  font-size: 15px;
}
.contact-content-social i {
  color: #4e4e4e;
}
/* .fa-linkedin{
  color: #005f90;
}
.fa-instagram-square{
  color: #405DE6;
}
.fa-twitter-square{
  color: #1399ea;
}
.fa-facebook-messenger{
  color: #006AFF;
}
.fa-envelope-square{
  color: #4285F4;
} */
.contact-content-social i:hover {
  color: var(--green);
}
.contact-content-social p {
  font-weight: 700;
  font-size: 18px;
}

/* ---------------Footer--------------- */
.footer-wrap {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.footer {
  width: var(--Dwidth);
  height: 150px;
  /* background-color: #2f2f2f; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-privacy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-privacy h1 {
  margin: 0px;
  padding: 0px;
  font-size: 21px;
  font-weight: 500;
}
.footer-privacy p {
  font-size: 14.5px;
  font-weight: 200;
  color: var(--green);
}
.footer-media {
  width: 130px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
/* .footer-media i{
  color: var(--green);
} */
.fa-youtube {
  color: #ff0000;
}
.fa-twitter {
  color: #1da1f2;
}
.fa-envelope {
  color: #006aff;
}
.footer-letsgo {
  font-size: 21px;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: underline 1px;
  text-underline-offset: 3px;
  text-decoration-color: var(--green);
}

/* --------------------service page------------------- */
.servicepage-wrap {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: var(--contentColor);
}
.servicepage-navbar-wrap {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  position: fixed;
  background-color: #f2f2f2;
}
.servicepage-navbar-wrap.navActive {
  /* position: absolute; */
  /* position: fixed;
  top: 0; */
  height: 60px;
}
.activebtn {
  background-color: var(--green) !important;
  color: white !important;
}
.servicepage-navbar {
  width: var(--Dwidth);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.servicepage-logo,
.servicepage-title-wrap {
  display: flex;
}
.servicepage-logo {
  width: 63px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}
.fa-angle-double-left {
  display: none;
  margin-right: 1px;
}
.servicepage-logo:hover .fa-angle-double-left {
  /* visibility: visible; */
  display: inline;
}
.servicepage-title-wrap {
  width: 250px;
  display: flex;
  justify-content: space-between;
}
.servicepage-ui,
.servicepage-development,
.contentpage-ui {
  height: 36px;
  width: 110px;
  background-color: var(--contentColor);
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}
.active {
  /* background-color: ; */
  color: var(--green);
}
.servicepage-work {
  width: 150px;
  height: 36px;
  box-shadow: 0 0 0 1px var(--green);
  /* background-color: var(--green); */
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--green);
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}
.servicepage-work:hover {
  background-color: var(--green);
  color: white;
}
.servicepage-work-logo {
  display: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
  position: fixed;
  z-index: 5;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px var(--green);
  /* background-color: #4caf50; */
  color: var(--green);
  cursor: pointer;
}
.servicepage-work-logo:hover {
  background-color: var(--green);
  color: white;
}
.fa-briefcase {
  font-size: 24px;
}
.servicepage-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
}
.servicepage-content h1{
  font-size: 27px;
  color: var(--green);
}
.servicepage-content-research {
  display: flex;
  width: var(--Dwidth);
  min-height: 300px;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.servicepage-content p {
  color: #2f2f2f;
  margin-bottom: 15px;
  font-weight: lighter;
}
.servicepage-content-research h1 {
  color: var(--red);
  font-size: 1.17em;
}
.servicepage-content-wireframming {
  display: flex;
  width: var(--Dwidth);
  min-height: 300px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
.sevicepage-content-letter2 {
  max-width: 500px;
  text-align: right;
}
.servicepage-content-wireframming h1 {
  color: var(--red);
  font-size: 1.17em;
}
.servicepage-content-wireframming p {
  color: #2f2f2f;
}
.sevicepage-content-letter1 {
  max-width: 500px;
  text-align: left;
}
.servicepage-content-image {
  max-width: 300px;
  max-height: 200px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 0 0.5px #4caf50;
}
.servicepage-content-image img {
  width: 110%;
  height: auto;
}
.servicepage-projects-title {
  height: 150px;
  /* background-color: #2f2f2f; */
}
.servicepage-projects {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.servicepage-projects-title h1 {
  color: rgba(120, 120, 120, 0.1);
  font-weight: bold;
  font-size: 60px;
  cursor: default;
}
.servicepage-projects-details {
  width: var(--Dwidth);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.servicepage-project-details-card {
  width: 300px;
  min-height: 150px;
  background-color: #2f2f2f;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* -----------------Content Page---------------- */
.contentpage-title-wrap {
  display: flex;
  /* background-color: yellow; */
  width: 350px;
  justify-content: space-between;
}
.servicepage-project-details-card img {
  width: 100%;
  height: auto;
}

/* -----------------Contact Page-------------------- */
.contactpage-wrap{
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.contactpage{
  width: var(--Dwidth);
}
.contactpage-navbar{
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
.contactpage-welcome{
  margin-top: 60px;
  height: 56px;
  /* background-color: #6d6f6d; */
  width: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: #2F2F2F;
  position: relative;
  padding-left: 20px;
  overflow: hidden;
}
.contactpage-welcome::before{
  content: "";
  background-color: #3a8c3d;
  height: 54px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: revel 1s forwards;
}
@keyframes revel{
  to{
    left: -100%;
  }
}
.contactpage-welcome::after{
  content: "";
  background-color: #3a8c3d;
  height: 54px;
  width: 6px;
  position: absolute;
  top: 0;
  left: 0;
}
.contactpage-welcome h3{
  margin: 0;
  padding: 0;
  font-style: normal;
font-weight: 500;
font-size: 24px;
}
.contactpage-welcome span{
  font-weight: bold;
}
.contactpage-welcome p{
  font-style: normal;
font-weight: 400;
font-size: 18px;
}
.contactpage-image-background{
  width: 196px;
  height: 196px;
  box-shadow: 0 0 0 2px var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactpage-actual-image{
  width: 185px;
  height: 185px;
  border-radius: 50%;
  background: rgba(196, 196, 196, 0.59);
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.contactpage-actual-image img{
  width: 252px;
  height: fit-content;
  padding-left: 18px;
    padding-top: 5px;
}
.contactpage-content-wrap{
  width: 100%;
  min-height: calc(100vh - 220px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contactpage-image-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactpage-social{
  width: 122px;
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}
.contactpage-social i{
  width: 30px;
  height: 30px;
  background-color: rgba(47, 47, 47, 0.67);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.contactpage-social i:hover{
  background-color: #2f2f2f;
}
a{
  text-decoration: none;
}
.contactpage-image-content{
  max-width: 321px;
}
.contactpage-image-content h1{
  padding: 0;
  margin: 0;
  color: rgba(76, 175, 80, 0.94);
  font-weight: bold;
font-size: 30px;
}
.contactpage-image-content p{
  color: #2F2F2F;
  font-weight: 300;
font-size: 13px;
}
.contactpage-image-content span{
  background: rgba(255, 82, 82, 0.74);
border-radius: 3px;
padding: 0px 4px 3px 4px;
font-size: 13px;
color: white;
cursor: default;
}
.contactpage-image-quotation{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.contactpage-image-quotation p{
  text-align: center;
  font-size: 15px;
  font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
  max-width: 250px;
}
.contentpage-quotation-left{
  width: 100%;
  display: flex;
  margin-bottom: -9px;
}
.contentpage-quotation-right{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -9px;
}
.fa-quote-left{
  text-align: left;
  color: var(--green);
}
.fa-quote-right{
  text-align: right;
  color: var(--green);
}
.contactpage-discuss-project-title{
  margin-top: 45px;
}
.contactpage-discuss-project-title h3{
  font-weight: 500;
font-size: 24px;
color: #2F2F2F;
font-style: normal;
margin: 0;
}
.contactpage-discuss-project-title p{
  font-style: normal;
font-weight: normal;
font-size: 18px;
color: #2F2F2F;
line-height: 21px;
margin-top: 10px;
}
.contactpage-form{
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.contactpage-form p{
  font-weight: normal;
font-size: 18px;
}
.contactpage-form form{
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  margin-top: 29px;
}
.contactpage-form1{
  width: 45%;
  height: 296px;
  /* background-color: #2f2f2f; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contactpage-form1 input{
  width: 100%;
  height: 36px;
  border: none;
  margin: 0;
  box-shadow: 0px 0px 1px #000000;
  background-color: transparent;
  border-radius: 3px;
  text-align: center;
}
.contactpage-form1 input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 1.5px var(--green);
}
::-webkit-input-placeholder {
  text-align: center;
}
.contactpage-form2{
  width: 45%;
  height: 296px;
  /* background-color: #2f2f2f; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contactpage-form2 textarea{
  max-width: 100%;
  min-width: 100%;
  max-height: 231px;
  height: 231px;
  border: none;
  margin: 0;
  box-shadow: 0px 0px 1px #000000;
  background-color: transparent;
  border-radius: 3px;
}
.contactpage-form2 textarea:focus {
  outline: none;
  box-shadow: 0px 0px 0px 1.5px var(--green);
}
.contactpage-form2 button{
  width: 101.5%;
  height: 36px;
  border-radius: 3px;
  background-color: var(--green);
  border: var(--green);
  font-weight: 500;
  font-size: 16px;
  color: white;
  cursor: pointer;
}





/* ----------------------Error Page----------------- */
.errorpage-main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorpage-wrap {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-wrap: wrap;
  width: 460px;
  max-height: 80vh;
  position: relative;
}
.errorpage {
  max-width: 312px;
}
.error-profile-wrap {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #bfbfbf;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-profile-online {
  width: 12px;
  height: 12px;
  background-color: #2e7c50;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  left: 11px;
  z-index: 1;
}
.error-profile-wrap img {
  width: 120px;
  height: auto;
}
.error-404 {
  width: 70px;
  /* height: 20px;s */
  box-shadow: 0 0 0 1px #989898;
  font-size: 12px;
  font-weight: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  color: #989898;
}
@media screen and (max-width: 404px) {
  .errorpage-wrap {
    justify-content: center;
    align-items: center;
  }
}
.fa-window-close {
  margin-top: 1px;
  padding: 0;
}
.errorpage-content h3 {
  font-size: 33px;
  font-weight: 500;
  text-align: left;
  color: var(--red);
  margin: 0;
}
.errorpage-content h4 {
  font-size: 33px;
  font-weight: 500;
  text-align: left;
  color: var(--green);
  margin: -10px 0 30px 0;
}
.errorpage-goback {
  box-shadow: 0 0 0 1px rgb(51, 51, 51);
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fa-chevron-left {
  display: none;
  margin-right: 6px;
  color: #464646;
}
.errorpage-goback:hover .fa-chevron-left {
  display: block;
}

/* --------------------Media Query------------------ */

@media screen and (min-width: 1008px) {
  .catagory-small-wrap {
    display: none;
  }
  .passive {
    display: none;
  }
  .navbar .fas {
    display: none;
  }
}

@media screen and (max-width: 1007px) and (min-width: 641px) {
  .catagory-small-wrap {
    display: none;
  }
  .passive {
    display: none;
  }
  .navbar {
    width: 95%;
  }
  .navbar .fas {
    display: none;
  }
  .hero {
    width: 95%;
  }
  .hero-image-wrap img {
    width: 340px;
    height: auto;
  }
  .service-content-wrap {
    width: 95%;
  }
  .about-content-letter {
    width: 55%;
  }
  .about-content {
    width: 95%;
  }
  .about-content-scroll-wrap {
    width: 100%;
  }
  .about-content-scroll {
    width: 95%;
  }
  .service-title {
    width: 95%;
  }
  .service-title h1 {
    font-size: 46px;
  }
  .service-image-content {
    width: 350px;
  }
  .content-title {
    width: 95%;
  }
  .content-title h1 {
    font-size: 46px;
  }
  .content-data {
    width: 95%;
  }
  .content-title-types {
    width: 300px;
  }
  .title-type-design {
    width: 70px;
  }
  .contact-content {
    width: 95%;
  }
  .contact-content-social {
    width: auto;
    font-size: 15px;
  }
  .footer {
    width: 95%;
  }
  .footer-privacy h1 {
    font-size: 20px;
  }
  .footer-privacy p {
    font-size: 13.5px;
  }
  .footer-letsgo {
    font-size: 18px;
  }
  .footer-media {
    font-size: 12px;
  }
  .servicepage-navbar,
  .servicepage-content-research,
  .servicepage-content-wireframming,
  .servicepage-projects-details, .contactpage {
    width: 95%;
  }

}

@media screen and (max-width: 640px) {
  .service-content {
    display: flex;
    flex-direction: column;
  }
  .navbar {
    width: 95%;
    position: relative;
  }
  .navbar .fas {
    font-size: 24px;
    cursor: pointer;
    width: 25px;
  }
  .passive {
    display: none;
  }
  .catagory-small-wrap {
    position: absolute;
    font-size: 15px;
    font-weight: 500;
    width: 100px;
    height: 130px;
    margin-top: 170px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0px 0px 0px 1px rgb(141, 141, 141);
  }
  .catagory-small-wrap p a {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgb(160, 160, 160);
  }
  .catagory-wrap {
    display: none;
  }
  .hero {
    position: relative;
  }
  .hero-content-wrap {
    position: absolute;
    z-index: 1;
    width: 95%;
    justify-content: center;
  }
  .hero-content-wrap h1 {
    text-align: center;
  }
  .hero-content-wrap h3 {
    text-align: center;
  }
  .hero-content {
    align-items: center;
    background-color: rgba(229, 229, 229, 0.2);
  }
  .hero-image-wrap {
    position: absolute;
    bottom: 0;
    z-index: 0;
    width: 95%;
    justify-content: center;
  }
  .hero-image-wrap img {
    opacity: 0.9;
    height: 550px;
    width: auto;
  }
  .service-title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 95%;
  }
  .service-title h1 {
    font-size: 46px;
    margin: 0;
  }
  .service-btn-wrap {
    width: 100%;
    justify-content: space-evenly;
  }
  .service-content-wrap {
    width: 100%;
  }
  .service-content {
    justify-content: space-around;
  }
  .service-images {
    height: auto;
    width: auto;
    margin: 20px 0;
  }
  .service-images .image-2 {
    top: 0;
  }
  .service-images .image-3 {
    bottom: 0;
  }
  .service-image-content {
    align-items: center;
    width: 95%;
  }
  .service-image-content p {
    text-align: center;
    margin-bottom: 20px;
  }
  .about-title h1 {
    font-size: 46px;
  }
  .about-content {
    width: 95%;
  }
  .about-content-letter {
    align-items: center;
    width: 100%;
  }
  .about-content-letter p {
    text-align: center;
  }
  .about-content-scroll {
    width: auto;
  }
  .about-content-image .service-images {
    display: none;
  }
  .content-title {
    width: 95%;
    height: 100%;
    flex-direction: column;
    /* margin: 0; */
    justify-content: space-around;
  }
  .content-title h1 {
    font-size: 46px;
    margin: 0;
    padding: 0;
  }
  .content-title-types {
    width: 100%;
    height: auto;
    justify-content: space-evenly;
  }
  .content-data {
    width: 95%;
  }
  .contact-title h1 {
    font-size: 49px;
  }
  .contact-content {
    width: 95%;
    align-items: center;
    flex-direction: column;
  }
  .contact-content-image {
    display: none;
  }
  .contact-content-field {
    width: 100%;
  }
  .contact-content-social {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: initial;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 10px;
  }
  .contact-content-social p {
    font-size: 24px;
    font-weight: 700;
    color: var(--green);
    display: none;
  }
  .footer {
    width: 95%;
    flex-direction: column-reverse;
    justify-content: start;
  }
  .footer-media {
    display: none;
  }
  .footer-letsgo {
    margin-bottom: 15px;
  }
  .servicepage-navbar,
  .servicepage-content-research,
  .servicepage-content-wireframming,
  .servicepage-projects-details {
    width: 95%;
  }
  .servicepage-content-research,
  .servicepage-content-wireframming {
    flex-direction: column;
    text-align: center;
  }
  .sevicepage-content-letter1,
  .sevicepage-content-letter2 {
    text-align: center;
  }
  .servicepage-projects-details {
    justify-content: center;
  }
  .servicepage-work-logo {
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .servicepage-work {
    display: none;
  }
  .contentpage-ui {
    width: 60px;
  }
  .servicepage-development {
    width: 97px;
  }
  .getBack-error-data,
  .getBack-data {
    width: calc(100% - 12px) !important;
  }
  .contactpage{
    width: 95%;
  }
  .contactpage-content-wrap{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
  }
  .contactpage-image-wrap{
    margin-bottom: 20px;
  }
  .contactpage-form form{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contactpage-form1, .contactpage-form2{
    width: 95%;
  }
  .contactpage-form2{
    margin-top: 29px;
  }
  .contactpage-welcome {
    margin-top: 6px;
  }
  .contactpage-welcome::after{
    height: 48px;
  }
  .contactpage-welcome::before{
    height: 48px;
  }
  .contactpage-welcome h3 {
    font-size: 23px;
}
  .contactpage-welcome p {
    font-size: 15px;
  }
  .contactpage-discuss-project-title p{
    font-size: 15px;
  }
  .contactpage-form {
    margin-top: 42px;
  }
  .contactpage-form p {
    font-size: 12px;
}
}
@media screen and (max-width: 405px) {
  .error-profile-online {
    display: none;
  }
}
@media screen and (min-height: 730px) and (max-height: 823px) {
  .hero-image-wrap img {
    height: 670px;
  }
}

.stop-data {
  display: none;
}
.getBack-data {
  width: 250px;
  height: 60px;
  background-color: var(--green);
  border-radius: 12px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: -65;
  right: 6px;
  position: fixed;
  z-index: 5;
  animation: mymove 6s;
  /* display: none; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.getBack-error-data {
  width: 250px;
  height: 60px;
  background-color: var(--red);
  border-radius: 12px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: -65;
  right: 6px;
  position: fixed;
  z-index: 5;
  animation: mymove 6s;
  /* display: none; */
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes mymove {
  0% {
    bottom: -65px;
    opacity: 0;
  }
  20% {
    bottom: 6px;
    opacity: 1;
  }
  80% {
    bottom: 6px;
    opacity: 1;
  }
  100% {
    bottom: -65px;
    opacity: 0;
  }
}
